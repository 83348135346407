<template>
    <v-app id="app">

      <payment-mask v-if="session.app_mode === 'donation'" iframe></payment-mask>

      <debug-bar v-if="session.app_mode !== 'donation'"></debug-bar>

      <payment-banner v-if="session.app_mode !== 'donation'"></payment-banner>

      <v-overlay color="white" v-if="session.state === 'initializing' && session.app_mode !== 'donation'">

        <v-progress-circular indeterminate color="primary"></v-progress-circular>

      </v-overlay>

      <router-view v-if="session.state !== 'initializing' && $route.meta.public === true && session.app_mode !== 'donation'"></router-view>
      <base-app v-else-if="session.state !== 'initializing' && $route.meta.public !== true && session.app_mode !== 'donation'"></base-app>

    </v-app>
</template>

<script setup>

import {sessionStore} from "@/store/sessionStore";
import BaseApp from "@/components/base/baseApp.vue";
import PaymentBanner from "@/components/user/PaymentBanner.vue";
import DebugBar from "@/components/DebugBar.vue";
import PaymentMask from "@/components/user/PaymentMask.vue";
const session = sessionStore();

</script>

<script>
export default {


    name: 'App',

  data(){


      return {


        init_interval: null

      }

  },
    beforeMount(){

      let self = this;
      self.$http.defaults.baseURL = process.env.VUE_APP_FORCED_SERVER;

      self.init_interval =   setInterval(function(){

        if(typeof self.$router.currentRoute.meta.public !== 'undefined'){

          self.session.init();
          clearInterval(self.init_interval);

        }

      }, 500);




    },

}
</script>


<style lang="scss">


@import '@/scss/master.scss';


</style>
