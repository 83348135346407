import axios from 'axios'

var apiClient = axios.create({
    baseURL: null,
    headers: {},
    crossdomain: true,


})

export default apiClient;