<template>


  <div>


    <div class="pa-3" v-if="environment === 'development'">

      <v-chip label class="ma-3">Environment: {{environment}}</v-chip>

      <v-chip label class="ma-3">Session State: {{session.state}}</v-chip>
      <v-chip label class="ma-3">Route is public: {{$route.meta.public}}</v-chip>
      <v-chip label class="ma-3">Route name: {{$route.name}}</v-chip>
      <v-chip label class="ma-3">Authenticated: {{session.is_authenticated}}</v-chip>
      <v-chip label class="ma-3">Admin: {{session.is_admin}}</v-chip>

      <v-chip color="primary" label class="ma-3">Hat bereits gespendet: {{!session.show_payment_banner}}</v-chip>


      <v-btn depressed @click="session.show_payment_banner = true">Zahlungs Popup</v-btn>

      <v-btn depressed @click="loginAdmin()" class="ml-3">Dev: Admin Login</v-btn>
      <v-btn depressed @click="loginUser()" class="ml-3">Dev: User Login</v-btn>

    </div>


  </div>

</template>


<script setup>

import {sessionStore} from "@/store/sessionStore";
const session = sessionStore();

</script>

<script>


import {isAxiosError} from "axios";

export default {


  name: 'DebugBar',
  data: function(){

    return {

      environment: null

    }

  },
  mounted() {

    this.environment = process.env.VUE_APP_ENV;

  },

  methods: {

    onLogIn(){

      let self = this;


      self.session.logout();
      self.session.login(self.user_login,self.user_pass,true).then(function(response){

        if(isAxiosError(response)){

         alert('Da hat was nicht funktioniert.');

        }


      });

    },


    loginUser(){


      let self = this;


      self.user_login = 'user@imbaa.de';
      self.user_pass = 'asdf1234567890';


      self.onLogIn();



    },

    loginAdmin(){


      let self = this;


      self.user_login = 'robinson-api-admin@zzml.de';
      self.user_pass = 'a;JO9wgU{7b9';


      self.onLogIn();

    }



  }

}

</script>