<template>


  <div>


    <div v-if="show_selector">


      <div v-if="show_ind_selector">

        <v-card  flat class="pt-10 pb-5 pl-10 pr-10 mb-5">

          <div class="text-h6 text-sm-h4 text-center">
            Mit <span class="font-weight-bold">{{ind_value}} EUR</span>
            <span v-if="recurring"> / Jahr</span> unterstützen
          </div>
          <v-slider v-model="ind_value" :min="ind_min" :max="ind_max" step="5"></v-slider>

          <div class="text-center">

            <v-btn class="primary" depressed @click="onClickAmountButton(ind_value)">Jetzt mit {{ind_value}} EUR<span v-if="recurring"> / Jahr</span> unterstützen</v-btn>

          </div>

        </v-card>

      </div>

      <div v-else>

        <div class="donation-holder">

          <div class="donation-tier" @click="onClickAmountButton(9)">

            <div class="donation-text">
              <span class="line">Mit</span>
              <span class="line">9 EUR
                <span v-if="recurring"> / Jahr</span>
              </span>
              <span class="line">unterstützen</span>
            </div>

          </div>

          <div class="donation-tier" @click="onClickAmountButton(15)">
            <div class="donation-text">
              <span class="line">Mit</span><span class="line">15 EUR<span v-if="recurring"> / Jahr</span></span><span class="line">unterstützen</span>
            </div>

          </div>

          <div class="donation-tier" @click="onClickIndButton(25)">
            <div class="donation-text">

              <span class="line">Betrag</span>
              <span class="line">selbst</span>
              <span class="line">festlegen</span>

            </div>

          </div>


        </div>

      </div>

      <div v-if="!iframe">
        <div class="d-flex justify-center mt-5" v-if="session.is_authenticated">

          <div v-if="recurring">

            <v-btn depressed  :disabled="!session.is_authenticated" color="primary" @click="recurring = !recurring" >
              <v-icon left>mdi-check-decagram</v-icon>Jährlich unterstützen
            </v-btn>

          </div>

          <div v-else>

            <v-btn depressed  :disabled="!session.is_authenticated" @click="recurring = !recurring" >
              <v-icon left small>mdi-check-decagram</v-icon>Jährlich unterstützen
            </v-btn>

          </div>

        </div>

        <div class="text-center" style="padding-top:10px; padding-bottom: 10px;font-size: 12px;">oder</div>
        <div class="d-flex justify-center pb-5">


          <v-btn depressed @click="dismissPaymentRequest()">Ohne Unterstützung weiter</v-btn>

        </div>
      </div>




    </div>

    <div v-if="show_email">

      <div class="text-center ma-3">

        <h1 class="text-h6 font-weight-bold mt-5 mb-1">Bitte geben Sie Ihre E-Mail Adresse ein</h1>
        <v-card-text >Mit Hilfe Ihrer E-Mail-Adresse können wir Ihnen die Bestätigung Ihrer Unterstützung zusenden.</v-card-text>

        <div style="max-width: 400px; margin: 0 auto;">

          <v-form
              ref="form"
              v-model="form_valid"

          >

            <v-text-field outlined label="Ihre E-Mail Adresse" type="email" class="mx-3" v-model="email" :rules="emailRules" required></v-text-field>

          </v-form>

          <div class="text-center mx-3">

            <v-btn
                class="primary mb-5"
                :disabled="!form_valid"
                depressed
                @click="onClickAmountButton(ind_value)">
              Betrag von {{ind_value}} EUR<span v-if="recurring"> / Jahr</span> bestätigen
            </v-btn>

          </div>

        </div>



      </div>

    </div>


  </div>

</template>



<script setup>

import {sessionStore} from "@/store/sessionStore";
const session = sessionStore();

</script>

<script>


export default {


  name: 'PaymentMask',
  props: {

    iframe: {required: false, default: false, type: Boolean}

  },
  data: function(){


    return {

      ind_value: 5,
      ind_min: 5,
      ind_max: 999,
      ind_steps: 5,
      show_ind_selector: false,
      recurring: this.session.is_authenticated,
      show_selector: true,
      show_email: false,
      form_valid: false,
      email: null,
      emailRules: [
        v => !!v || 'Bitte geben Sie eine E-Mail Adresse an',
        v => /.+@.+\..+/.test(v) || 'Bitte geben Sie eine gültige E-Mail Adresse an',
      ],

    }


  },

  methods: {

    initiatePayment: function(recurring = false){


      let self = this;

      if(recurring){

        self.session.initiatePayment(recurring,self.ind_value)

      } else {

        self.session.initiatePayment(recurring,self.ind_value,self.email)

      }



    },

    onClickAmountButton(amount){


      let self = this;
      self.ind_value = amount;


      if(self.session.is_authenticated || self.form_valid){

        self.initiatePayment(self.recurring);

      } else {

        self.show_email = true;
        self.show_selector = false;

      }

    },

    onClickIndButton(value){


      let self = this;
      self.ind_value=value;
      self.show_ind_selector = true;





    },

    dismissPaymentRequest(){

      let self = this;

      self.recurring = true;
      self.session.dismissPaymentRequest();

    }

  }

}

</script>


<style lang="scss">


.donation-holder {

  margin: 20px 0 0 20px;
  gap: 10px;
  display: flex;
  flex-flow: column;

  @media screen and (min-width: 600px){


    justify-content: space-between;
    flex-flow: row;


  }

  .donation-tier {

    flex-grow: 1;
    text-align: center;
    font-weight: bold;

    border: 1px solid #34a9e1;
    background-color: #34a9e1;
    color:#ffffff;

    gap: 10px;
    border-radius: 5px;

    @media screen and (max-width:599px){

      padding: 15px;
      display: flex;
      justify-content: center;
      gap: 10px;

      .line:after {

        content: " ";

      }

    }

    @media screen and (min-width: 600px){

      font-size: 20px;
      padding: 30px;

      .line {
        display: block;
      }


    }

    &:hover {

      background-color: #f9f9f9;

      color: #34a9e1;
      cursor: pointer;

    }


  }


}


</style>
