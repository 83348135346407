import { defineStore } from 'pinia';
import  APIService  from '@/services/APIService'
import protectionTypes from '@/router/userProtectionTypes.json';


export const sessionStore = defineStore('session', {
    state: () => ({
        app_version: process.env.VUE_APP_GIT_HASH,
        app_mode:process.env.VUE_APP_MODE,
        state: 'initializing',
        is_mobile: false,
        is_admin: false,
        token: localStorage.getItem('token'),
        is_authenticated: false,
        personal_entries: {},
        protection_types: false,
        list_user_id: null,
        user_data: {},
        show_payment_banner: false,
        initiating_payment: false,
        maxEntriesPerList: 10,
        is_deleted: false,
        payment_status: null,
        lists: {

            post: false,
            email: false,
            telefon: false,
            mobil: false

        }
    }),

    getters: {

        getLists(state){
            return state.lists;
        },

        getOfferDonation(state){

            if(!state.payment_status) {return true;}
            return typeof state.payment_status.cancelUrl === "undefined";

        },

        getHasDonationSubscription(state){

            if(!state.payment_status) {return false;}
            return typeof state.payment_status.cancelUrl !== "undefined";

        }

    },

    actions: {

        init(){

            let self = this;

            self.protection_types = protectionTypes;
            APIService.defaults.headers.common['Authorization'] = 'Token '+self.token;


            self.checkPaymentStatus(false,true);

            if(!self.token){

                if(typeof self.router.currentRoute.meta.public !== 'undefined'){

                    if(!self.router.currentRoute.meta.public && self.router.currentRoute.name !== 'login'){
                        self.router.replace({name: 'login'});
                    }

                }

                self.state = 'ready';
                return;

            }

            self.ping().then(function(authenticated){

                self.is_authenticated = authenticated;
                self.state = 'ready';


                if(!self.is_admin){

                    self.loadLists();

                }


            });

        },

        setToken(token){

            let self = this;

            self.token = token;
            localStorage.setItem('token',self.token);
            APIService.defaults.headers.common['Authorization'] = 'Token '+token;

        },


        ping(){

            let self = this;



            return APIService.get('/whoAmI').then(function(response){


                self.is_authenticated = response.data.isActive;
                self.user_data = response.data;
                self.is_admin = self.user_data.isAdmin;
                self.maxEntriesPerList = self.user_data.maxEntriesPerList

                if(self.router.currentRoute.meta.public && self.router.currentRoute.name !== 'app'){


                    if(self.router.currentRoute.meta.prevent_app_redirect !== true){

                        self.router.replace({name: 'app'});

                    }



                }

                if(self.router.currentRoute.meta.force_logout){
                    self.logout(false);
                }

                self.state = 'ready';
                return true;

            }).catch(function () {


                if(!self.router.currentRoute.meta.public && self.router.currentRoute.name !== 'login'){

                    self.router.replace({name: 'login'});

                }

                self.is_authenticated = false;

                return false;

            });

        },

        loginWithToken(token){


            let self = this;

            self.setToken(token);
            self.ping().then(function(){

                if(!self.is_admin){
                    self.loadLists();
                }


                self.router.push({ name: 'app' }).catch(()=>{}); // Navigate to the 'app' route after logout



            });

        },

        login(email, password, redirect = false) {

            let self = this;

            self.state = 'loading';

            return APIService.post('/login', {email:email,password:password}).then(function(response){



                self.setToken(response.data.jwt);
                self.ping().then(function(){

                    if(!self.is_admin){
                        self.loadLists();
                        self.checkPaymentStatus(true);
                    }


                });

                if(redirect){
                    self.router.push({ name: 'app' }).catch(()=>{}); // Navigate to the 'app' route after logout
                }




                return response;


            }) .catch(function (error) {

                self.state = 'ready';
                return error;


            });

        },


        signup(data){


            let self = this;
            self.state = 'loading';

            return APIService.post('/register',data).then(function(response){
                self.state = 'ready';
                return response;
            }).catch(function (error) {

                self.state = 'ready';
                return error;


            });


        },

        logout(redirect = false) {

            let self = this;

            if(redirect){
                self.router.push({ name: 'login' }).catch(()=>{}); // Navigate to the 'login' route after logout
            }

            localStorage.removeItem('token')
            self.is_authenticated = false;
            self.list_user_id = null;

            self.$reset();
            self.state = 'ready';

            self.init();

        },


        loadLists(){

            let self = this;
            if(!self.is_authenticated){return;}

            protectionTypes.forEach(function(type){

                self.loadList(type.name)

            });

        },

        loadList(type) {

            let self = this;
            let url ='/lists/'+type;

            if(self.list_user_id !== null){
                url = url+'?uid='+self.list_user_id;
            }

            return APIService.get(url).then(function(response){

                if(response.data.result){
                    self.lists[type] = response.data.result;
                } else {
                    self.lists[type] = response.data;
                }

            }).catch(function (error) {

                return error;

            });

        },

        createEntry(type, data){

            let self = this;
            self.state = 'saving';

            data.uid = self.list_user_id;

            return APIService.post('/lists/'+type,data).then(function(){

                self.state = 'ready';
                return self.loadList(type);


            }).catch(function (error) {

                self.state = 'ready';
                return error;

            });

        },


        updateEntry(type, data){

            let self = this;

            return APIService.put('/lists/'+type+'/'+data.id,data).then(function(){

                self.state = 'ready';
                return self.loadList(type);


            }).catch(function (error) {

                self.state = 'ready';
                return error;

            });



        },


        deleteEntry(type,id){


            let self = this;
            self.state = 'saving';


            return APIService.delete('/lists/'+type+'/'+id).then(function(){

                self.state = 'ready';
                return self.loadList(type);


            }).catch(function (error) {

                self.state = 'ready';
                return error;

            });


        },


        checkPaymentStatus(force_popup = false, prevent_popup = false){

            let self = this;

            if(self.is_admin){return;}

            APIService.get('/payment/status').then(function (response) {
                self.payment_status = response.data;
            }).catch(function () {

                if(prevent_popup){return;}
                //invalid response code
                if(!force_popup && localStorage.getItem('rbl::donation_dismissed') === 'yes'){return;}
                self.show_payment_banner = true;



            });


        },


        deleteProfile(){


            let self = this;


            APIService.delete('/users/' + self.user_data.id).then(function () {
                self.is_deleted = true;
                setTimeout(function () {
                    self.logout();
                }, 3000);

            });

        },

        initiatePayment(recurring = false, amount = 5, email){

            let payload = {};
            let self = this;

            self.initiating_payment = true;

            switch(recurring){

                case true:

                    payload = {amount: amount, recurring: true, redirectUrlAfterPayment: "https://"+window.location.hostname+'/'};

                    break;


                case false:

                    payload = {amount: amount, oneoff:true, redirectUrlAfterPayment:  "https://"+window.location.hostname+'/', email};

                    break;

            }

            APIService.post('/payment/init',payload).then(function (response) {

                window.location.href=response.data.redirectToPaymentUrl;
                self.initiating_payment = false;

            }).catch(function () {

                //invalid response code
                alert('Ein Fehler ist aufgetreten. Bitte versuchen Sie es später noch ein mal.');
                self.initiating_payment = false;

            });

        },


        dismissPaymentRequest(){

            let self = this;
            self.show_payment_banner = false;
            localStorage.setItem('rbl::donation_dismissed','yes')

        }

    },
});
