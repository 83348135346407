import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const mode = process.env.VUE_APP_MODE === 'donation' ? 'abstract' : 'history';
const routes = [

  {
    path: '/',
    name: 'login',
    component: () => import(/* webpackChunkName: "user" */ '../views/Frontend/LoginView.vue'),
    meta: {public:true}
  },
  {
    path: '/password-recovery',
    name: 'password-recovery',
    component: () => import(/* webpackChunkName: "user" */ '../views/Frontend/LostPassword.vue'),
    meta: {public:true}
  },
  {
    path: '/verify-email',
    name: 'activate',
    component: () => import(/* webpackChunkName: "user" */ '../views/Frontend/ConfirmMailView.vue'),
    meta: {public:true}
  },
  {
    path: '/change-email',
    name: 'confirm-mail',
    component: () => import(/* webpackChunkName: "user" */ '../views/Frontend/ChangeEmailView.vue'),
    meta: {public:true, prevent_app_redirect: true}
  },

  {
    path: '/signup',
    name: 'signup',
    component: () => import(/* webpackChunkName: "user" */ '../views/Frontend/SignupView.vue'),
    meta: {public:true}
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import(/* webpackChunkName: "admin" */ '../views/AdminView.vue'),
    meta: {public:false}
  },
  {
    path: '/donate',
    name: 'donate',
    component: () => import(/* webpackChunkName: "admin" */ '../views/DonateView.vue'),
    meta: {public:true}
  },
  {
    path: '/app/:view?',
    name: 'app',
    component: () => import(/* webpackChunkName: "user" */ '../views/UserView.vue'),
    meta: {public:false}
  },
]

const router = new VueRouter({
  mode: mode,
  base: process.env.BASE_URL,
  routes
})

export default router

