<template>
    <div>

        <v-main>

            <v-container>
                <v-card flat outlined class="mb-5">
                    <v-toolbar flat>

                        <v-img
                            class="mx-2"
                            :src="require('@/assets/robinsonliste-logo.png')"
                            max-height="50"
                            max-width="200"
                            contain
                        ></v-img>

                        <v-spacer></v-spacer>


                        <v-btn color="orange" v-if="session.getOfferDonation" style="color: #ffffff;" depressed @click="session.show_payment_banner = true" class="hidden-xs-only">
                          <v-icon left>mdi-hand-coin</v-icon>JETZT unterstützen!</v-btn>
                        <v-btn depressed class="ml-1" @click="session.logout(true)">Abmelden <v-icon right>mdi-logout</v-icon></v-btn>

                    </v-toolbar>
                </v-card>

                <router-view></router-view>
            </v-container>

        </v-main>

    </div>
</template>

<script setup>

import {sessionStore} from "@/store/sessionStore";
const session = sessionStore();

</script>

<script>


export default {

    name: 'baseApp',

}

</script>
