<template>

  <div>


    <v-dialog v-model="session.show_payment_banner" modal max-width="1000" persistent>

      <v-card flat>

        <div>

        <div class="d-flex justify-center" v-if="session.initiating_payment">

          <v-progress-circular indeterminate class="ma-10" size="100" color="primary"></v-progress-circular>

        </div>

        <div class="pa-5" v-if="!session.initiating_payment">


          <div class="text-center">


            <h3 class="text-h4 font-weight-bold" style="color: #34a9e1;">Wir brauchen Ihre Unterstützung!</h3>
            <h4 class="text-h5 font-weight-bold">Auch kleine Beträge helfen.</h4>

            <p class="mt-3">

              Wir kümmern uns um den Schutz Ihrer persönlichen Daten und vermeiden ungewollte Werbung für Sie!<br>
              Wir sind für unsere gemeinnützige Tätigkeit aber auf Spenden angewiesen!

            </p>

          </div>

          <payment-mask></payment-mask>

          <div class="text-center">

            <p class="font-weight-bold mb-1">Seit über 25 Jahren die Nr. 1 im Verbraucherschutz gegen Werbung<br></p>
            <p style="color: #333; font-size:10px; max-width:650px; margin: 0 auto;">

              Die Deutschen Robinsonlisten sind mit über 4 Mio. Nutzern die größte unabhängige Verbraucherschutzhilfe in Deutschland.
              Der Eintrag in die Deutsche Robinsonliste ist IMMER kostenlos! Helfen Sie, auch Ihre Familie zu schützen und tragen Sie direkt Ihre Eltern, Großeltern und Kinder in unsere Schutzlisten ein.

            </p>

          </div>


        </div>


        </div>

      </v-card>
    </v-dialog>

  </div>

</template>


<script setup>

import {sessionStore} from "@/store/sessionStore";
import PaymentMask from "@/components/user/PaymentMask.vue";

const session = sessionStore();

</script>

<script>


export default {

  name: 'PaymentBanner',
  data: function () {

    return {
      dialog: true
    }

  },


  computed: {


    size_of_pay_once: function(){


      let self = this;

      if(self.session.is_authenticated){

        return 4;

      }

      return 12;


    }

  }

}

</script>
