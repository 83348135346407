import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import { markRaw } from 'vue'
import APIService from './services/APIService'


import router from './router'
import { createPinia, PiniaVuePlugin } from 'pinia'

import i18n from './i18n'


import VueCompositionAPI from '@vue/composition-api'

Vue.use(VueCompositionAPI)

Vue.use(PiniaVuePlugin)
Vue.use(require('vue-moment'));

Vue.prototype.$http = APIService;

const pinia = createPinia()
pinia.use(({ store }) => { store.router = markRaw(router) });



Vue.config.productionTip = false

new Vue({
  vuetify,
  router,
  pinia,
  i18n,
  render: h => h(App)
}).$mount('#app')
